<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 reception-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: backLinkName }">
            <button>
              <icon icon="#cx-hea1-arrow-left" />
            </button>
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "visitor-management",
              "manage-reception-desks",
              "level"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <ul
      class="clebex-item-section pill"
      v-if="listOfLevels && listOfLevels.length"
    >
      <li
        class="clebex-item-section-item"
        v-for="lvl in listOfLevels"
        :key="lvl.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="setSelectedReceptionLevel(lvl)"
        >
          <span class="label">
            <span class="text">
              <span class="highlight">{{
                lvl.all_parents && lvl.all_parents.length
                  ? `${lvl.name},`
                  : lvl.name
              }}</span>
              {{ displayParentNames(lvl.all_parents) }}
            </span>
          </span>
          <span class="follow-up-icons">
            <span
              class="follow-up-icon-item active"
              v-if="receptionLevel && receptionLevel.id === lvl.id"
            >
              <icon icon="#cx-app1-checkmark"></icon>
            </span>
          </span>
        </button>
      </li>
    </ul>
  </mobile-screen>
</template>

<script>
import { mapState, mapActions } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
export default {
  name: "ReceptionLeves",
  computed: {
    ...mapState("reception", ["levels", "receptionLevel"]),
    listOfLevels() {
      if (this.levels && this.levels.length) {
        return this.levels;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("reception", ["getLevels", "setReceptionLevel"]),
    setSelectedReceptionLevel(level) {
      this.setReceptionLevel(level);
      this.$router.push({ name: this.backLinkName });
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter(item => item.name);
      return filteredParents.map(parent => parent.name).join(", ");
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
